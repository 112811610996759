import React from "react"
import styled from "@emotion/styled"

import colors from "../styles/colors"
import { DesktopMediaQuery, MobileMediaQuery } from "../styles/constants"
import Typography, { typographyComponents } from "./typography"
import { HoverButton } from "./button"
import { Check } from "./icons/check"
import { useCookieConsentContext } from "../contexts/cookieConsent"
import Link from "./link"

const FixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 4px;
  right: 4px;
  z-index: 1000000;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${p => (p.showCookieSettings ? "400px" : "180px")};
  max-width: 1240px;
  margin: auto;
  padding: 10px 100px;
  background: ${colors.darkgrey};
  color: ${colors.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${MobileMediaQuery} {
    flex-direction: column;
    padding: 20px 24px;
    max-width: 330px;
  }

  ${DesktopMediaQuery} {
    transition: height 350ms;
    align-items: ${p => (p.showCookieSettings ? "flex-start" : "center")};
    padding-top: ${p => (p.showCookieSettings ? "74px" : "10px")};
  }
`

const ExtraMargin = styled.div`
  height: 60px;
`

const Notice = styled(typographyComponents.subtitleSmall)`
  display: inline-block;

  ${MobileMediaQuery} {
    width: 100%;
    text-align: center;
  }

  ${DesktopMediaQuery} {
    white-space: nowrap;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  ${DesktopMediaQuery} {
    justify-content: flex-end;
  }
`
const Button = styled(HoverButton)`
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 1000px;
  min-width: 130px;
  text-transform: none;
  outline: none;

  ${DesktopMediaQuery} {
    &:first-of-type {
      margin-right: 22px;
    }
  }
`

const Settings = styled.div`
  display: flex;
  margin-top: 40px;

  ${MobileMediaQuery} {
    margin-top: 30px;
  }
`
const List = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MobileMediaQuery} {
    height: 192px;
  }
`
const Item = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 17px;
  font-weight: 700;

  ${DesktopMediaQuery} {
    margin-bottom: 16px;
  }
`
const Text = styled.div`
  font-size: 11px;

  ${MobileMediaQuery} {
    flex: 1;
  }

  ${DesktopMediaQuery} {
    width: 300px;
    margin-left: 65px;
  }
`

const NativeCheckbox = styled.input`
  position: absolute;
  opacity: 0;
`

const CheckboxLabel = styled.label`
  width: 29px;
  height: 29px;
  border-radius: 1000px;
  border: 2px solid ${colors.white};
  margin-right: 6px;
  cursor: pointer;
`

const CheckboxTitle = styled(Typography)`
  font-size: 11px;
  cursor: pointer;
`

const CookieDeclaration = styled(Typography)`
  font-size: 11px;

  ${MobileMediaQuery} {
    margin-top: 20px;
    display: inline-block;
  }
`

const DesktopGroup = styled.div``

const CookieLink = styled(Link)`
  color: ${colors.white};
`

const cookieGroups = [
  {
    name: "Necessary",
    alwaysSelected: true,
    text:
      "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
  },
  {
    name: "Preferences",
    alwaysSelected: false,
    text: `Preference cookies enable a website to remember information that changes
        the way the website behaves or looks, like your preferred language or
        the region that you are in.`,
  },
  {
    name: "Statistics",
    alwaysSelected: false,
    text: `Statistic cookies help website owners to understand how visitors
        interact with websites by collecting and reporting information
        anonymously.`,
  },
  {
    name: "Marketing",
    alwaysSelected: false,
    text: `Marketing cookies are used to track visitors across websites. The
        intention is to display ads that are relevant and engaging for the
        individual user and thereby more valuable for publishers and third party
        advertisers.`,
  },
]

const Checkbox = ({ checked = false, onChange }) => {
  const toggle = state => {
    const nextState = !state
    onChange(nextState)
  }

  return (
    <>
      <NativeCheckbox
        type="checkbox"
        checked={checked}
        onChange={() => toggle(checked)}
      />
      <CheckboxLabel onClick={() => toggle(checked)}>
        <Check style={{ opacity: checked ? 1 : 0 }} />
      </CheckboxLabel>
    </>
  )
}

const CookieBanner = () => {
  const { initialConsent, isOpen, close } = useCookieConsentContext()
  const [showCookieSettings, setShowCookieSettings] = React.useState(false)
  const [selectedCookieGroup, setSelectedCookieGroup] = React.useState(0)
  const [cookieConsent, setCookieConsent] = React.useState(
    new Set(initialConsent)
  )

  const wrapWithClose = () => () => {
    let consent
    if (showCookieSettings) {
      consent = [...cookieConsent]
    } else {
      consent = cookieGroups
        .filter(cg => cg.name !== "Necessary")
        .map(cg => cg.name)
      setCookieConsent(() => new Set(consent))
    }

    close(consent)
  }

  if (!isOpen) return null

  const selectCookieGroupText = name => {
    setSelectedCookieGroup(cookieGroups.findIndex(cg => cg.name === name))
  }

  return (
    <>
      <FixedWrapper>
        <Wrapper showCookieSettings={showCookieSettings}>
          <DesktopGroup>
            <Notice>We use cookies</Notice>

            {showCookieSettings && (
              <>
                <Settings>
                  <List>
                    {cookieGroups.map((cookie, i) => (
                      <Item key={cookie.name}>
                        <Checkbox
                          checked={
                            cookie.alwaysSelected ||
                            cookieConsent.has(cookie.name)
                          }
                          onChange={toggle => {
                            selectCookieGroupText(cookie.name)
                            if (toggle) {
                              setCookieConsent(s => new Set(s).add(cookie.name))
                            } else {
                              setCookieConsent(s => {
                                const newSet = new Set(s)
                                newSet.delete(cookie.name)
                                return newSet
                              })
                            }
                          }}
                        />
                        <CheckboxTitle
                          variant="bodySmall"
                          onClick={() => selectCookieGroupText(cookie.name)}
                        >
                          {cookie.name}
                        </CheckboxTitle>
                      </Item>
                    ))}
                  </List>
                  <Text>{cookieGroups[selectedCookieGroup].text}</Text>
                </Settings>
                <CookieDeclaration variant="bodySmall">
                  <CookieLink to="/cookie-policy/">
                    Cookie declaration
                  </CookieLink>{" "}
                  updated 25 February 2021
                </CookieDeclaration>
              </>
            )}
          </DesktopGroup>

          <Buttons>
            <Button
              center
              size="s"
              onClick={() => setShowCookieSettings(s => !s)}
              ghost
              variant="dark"
            >
              {showCookieSettings ? "Hide" : "Manage"}
            </Button>
            <Button center size="s" onClick={wrapWithClose()} variant="white">
              {showCookieSettings ? "Accept" : "Accept all"}
            </Button>
          </Buttons>
        </Wrapper>
      </FixedWrapper>
      <ExtraMargin />
    </>
  )
}

export default CookieBanner
